import { allChains, chain } from "wagmi";

const lookupChain = (chainId: number) => {
  for (const chain of allChains) {
    if (chain.id === chainId) return chain;
  }

  return chain.hardhat;
};

export const CONTRACT_ADDRESS = process.env.CONTRACT_ADDRESS ?? "0x5FbDB2315678afecb367f032d93F642f64180aa3";
export const CHAIN = process.env.CHAIN_ID ? lookupChain(parseInt(process.env.CHAIN_ID)) : chain.hardhat;
export const WEBSITE_URL = process.env.WEBSITE_URL ?? "";
export const OS_SLUG = process.env.OS_SLUG ?? "";

export const INFURA_ID = process.env.INFURA_ID ?? "9d70bdb29dda4b8c88746c0f91505a29";
